/* eslint-disable jsx-a11y/alt-text */
import './exchange.css';
import exchange_twink from '../assets/images/exchange_twink.png';
import logo from '../assets/images/logo.png';
import { isSpring, timeRemaining } from '../util';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useJockExchange } from '../hooks/useJockExchange';

const Exchange = () => {
    const [page, setPage] = useState<number>(0);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [size, setSize] = useState<string>("");
    const [note, setNote] = useState<string>("");

    const jockExchange = useJockExchange();
    
    const pageOne = () => {
        return (
            <>
                <Typography
                    align='center'
                    sx={{fontSize: "1.4rem"}}
                >
                    The Jock Exchange
                </Typography>
                <Typography
                    align='center'
                    sx={{fontSize: "0.9rem"}}
                >
                    Entries close in: {timeRemaining()}
                </Typography>
                <Divider />
                <Typography
                    align='center'
                    sx={{fontSize: "1rem"}}
                >
                    Hey there, daddy! <i>Submit</i> your name, email, and jockstrap waist size and you will be entered into the daddies day jock exchange! Another <i>daddy</i> will be notified that they are gifting you a jock and what size they should get. Likewise, you will be randomally matched with someone to be their jock daddy. <i>Daddy Provides</i>!
                </Typography>
                <Divider />
                <Button
                    variant='contained'
                    onClick={() => setPage(1)}
                >
                    Continue
                </Button>
            </>
        )
    }

    const pageTwo= () => {
        return (
            <>
                <Typography
                    align='center'
                    sx={{fontSize: "1.4rem"}}
                >
                    The Jock Exchange
                </Typography>
                <Typography
                    align='center'
                    sx={{fontSize: "0.8rem"}}
                >
                    Enter now to match with your daddy!
                </Typography>
                <TextField 
                    variant='outlined'
                    label='First Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <TextField 
                    variant='outlined'
                    label='Email Address'
                    placeholder="To notify you of your match"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <FormControl>
                    <InputLabel id="x-size-label">Your Jock Size</InputLabel>
                    <Select
                        labelId='x-size-label'
                        label="Your Jock Size"
                        variant='outlined'
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                        required
                    >
                        <MenuItem value="xs">Extra Small</MenuItem>
                        <MenuItem value="s">Small</MenuItem>
                        <MenuItem value="m">Medium</MenuItem>
                        <MenuItem value="l">Large</MenuItem>
                        <MenuItem value="xl">Extra Large</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    variant='outlined'
                    label="Notes (Optional)"
                    multiline
                    rows={4}
                    placeholder="Any additional notes or requests that you think your daddy should know?"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <Button
                    variant='contained'
                    disabled={!name || !email || !size}
                    onClick={() => {
                        setPage(2);
                        setSubmitted(true);
                        jockExchange.mutate({
                            name,
                            email,
                            size,
                            note
                        });
                    }}
                >
                    Submit Entry
                </Button>
            </>
        )
    }

    const pageThree = () => {
        return (
            <>
                <Typography
                    align="center"
                >
                    {jockExchange.isPending && "Submitting your entry..."}
                    {jockExchange.isError && "There was an error submitting your entry."}
                    {jockExchange.isSuccess && `
                    Welcome to the Jock Exchange ${name}! You will be notified by email of your match in ${timeRemaining()}.`}
                </Typography>
            </>
        )
    }

    const pages = [
        pageOne(),
        pageTwo(),
        pageThree(),
    ]


    const closed = () => {
        return (
            <div>
                <div
                    className="closed-banner"
                >
                    The Jock Exchange is now closed. 
                </div>
                <div className="twink_container"
                    style={{
                        bottom: 0,
                        right: 0,
                    }}
                >
                    <img 
                        src={exchange_twink} 
                        className="twink" 
                    />
                </div>
            </div>
        )
    };

    const open = () => {
        return (
            <div>
                <div id="exchange-pages"
                    style={submitted ? {
                        marginTop: "-30vh"
                    } : {}}
                >
                    {pages[page]}
                </div>
                <div className="twink_container"
                    style={submitted ? {
                       bottom: 0,
                       right: 0 
                    } : {}}
                >
                    <img src={exchange_twink} className="twink" />
                </div>
            </div>
        )
    }

    return (
        <div id="exchange-page">
            <div className="exchange-logo"
                style={{
                    width: "130px",
                    height: "60px",
                    background: `url(${logo})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}
            />
            {isSpring() ? open() : closed()}
        </div>
    );
}

export default Exchange;