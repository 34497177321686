import { useMutation } from "@tanstack/react-query"
import { axiosClient } from "../AxiosClient";

export type JockExchangeData = {
    name: string;
    email: string;
    size: string;
    note?: string;
}

export const useJockExchange = () => {
    return useMutation({
        mutationFn: (data: JockExchangeData) => axiosClient.post("", data),
        onMutate: (data: JockExchangeData) => {
            console.log("onMutate", data);
        },
        onError: (error: any, variables: any, context: any) => {
            console.log("onError", error, variables, context);
        },
        onSuccess: (data: any, variables: any, context: any) => {
            console.log("onSuccess", data, variables, context);
        }
    });
}
