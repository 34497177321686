import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Exchange from './pages/Exchange';
import Home from './pages/Home';

const queryClient = new QueryClient();

function App() {

  const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/exchange', element: <Exchange /> }
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
