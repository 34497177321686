

/**
 * This function will return true if the current day is between  March 15th and
 * May 15th of any year.
 */
export const isSpring = (): boolean => {
    const today = new Date();
    const month = today.getMonth();
    const day = today.getDate();
    return (month === 2 && day >= 15) || (month === 3 && day <= 28);
}

/**
 * This function returns time remaining between now and May 15th of the current year.
 */
export const timeRemaining = (): string => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const may15 = new Date(currentYear, 3, 28); // May is month 4 in JavaScript
    const diffTime = Math.abs(may15.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return `${diffDays} days.  (April 28th, ${currentYear})`;
}