import { createTheme } from '@mui/material/styles';


declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: string;
      };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      status?: {
        danger?: string;
      };
    }
  }

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E1B18',
    },
    secondary: {
      main: '#FFFAFF',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#000000',
    },
  },
    status: {
      danger: "#ff0000",
    },
});

export default theme;
  