import './home.css';
import exchange_twink from '../assets/images/exchange_twink.png';
import logo from '../assets/images/logo.png';

const Home = () => {
    return (
        <div id="home-page">
            <img src={logo} className="logo" />
        </div>
    );
}

export default Home;